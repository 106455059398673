'use strict';

import { easeOutExpo } from '../../_js/_util';

export default class Accordion {
    constructor() {
        $('.-js-toggle').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $this = $(this),
                $header = $this.parent(),
                $body = $header.next();

            $this.toggleClass('-active');
            $body.slideToggle(easeOutExpo);

            $('html, body').animate({
                scrollTop: $header.offset().top - 50
            }, 750);
        });

        $('.-js-toggle-all').on('click', function (e) {
            e.preventDefault();

            const $this = $(this);

            $this.toggleClass('-active');

            if ($this.hasClass('-active')) {
                $this.text('Collapse all');
                $('.-js-toggle').addClass('-active');
                $('.accordion__body').slideDown(easeOutExpo);
            } else {
                $this.text('Expand all');
                $('.-js-toggle').removeClass('-active');
                $('.accordion__body').slideUp(easeOutExpo);
            }


            $('html, body').animate({
                scrollTop: $('.accordion__header:first-child').offset().top - 50
            }, 750);

        });

        $('.accordion__header').on('click', function (e) {
            e.preventDefault();

            $(this).find('.-js-toggle').trigger('click');
        });
    }
}
