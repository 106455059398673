'use strict';

import { ripple } from '../../_js/_material';
import { debounce, isMobile, easeOutExpo } from '../../_js/_util';

export default class Navigation {
    constructor() {

        const that = this;
        const $body = $('body');
        const el = $('#navigation');
        const $nav = el.find('.nav');
        const $lvl1 = el.find('.lvl1');
        const $dropdownList = el.find('ul li');
        const $primaryNav = $('.js-mobile-menu');
        const $subNav = $('.js-sub-nav');
        const subNavMarkup = '<button class="sub-nav js-sub-nav icon-arrow" name="Sub-navigation"></button>';

        let isMobileDevice = isMobile();

        that.$window = $(window);
        that.$visibleArea = 0;

        // Insert Subnav Markup after Level 1 menu items
        $lvl1.find('ul').each(function () {
            $(this).before(subNavMarkup);
        });

        that.checkNavHeight();

        $primaryNav.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            const $this = $(this);

            el.toggleClass('-active');
            $this.toggleClass('-active');
            $nav.toggleClass('-active');
            $lvl1.toggleClass('-active');

            if ($this.hasClass('-active')) {
                $lvl1.slideDown(easeOutExpo);
            } else {
                $lvl1
                    .removeClass('-active')
                    .find('.icon-arrow.-active')
                    .removeClass('-active');
            }
        });

        $subNav.on('touchend, click', function () {
            const $this = $(this);
            const $grandParent = $this.parent().parent();
            const $next = $this.next();

            if ($this.hasClass('-active')) {
                $this.removeClass('-active');
                $next.removeClass('-active').slideUp(easeOutExpo);
            } else {
                $lvl1
                    .find('.icon-arrow.-active')
                    .removeClass('-active')
                    .next().removeClass('-active').slideUp(easeOutExpo);

                $this.addClass('-active');

                setTimeout(function () {
                    $next.addClass('-active');
                }, 200);

                $next.slideDown(easeOutExpo);
            }
        });

        // Primary Nav Mouse Listeners
        el.on('click', '.no-link', function (e) {
            e.preventDefault();

            const $this = $(this);
            const $next = $this.next();

            $next.trigger('click');

            ripple(e, $this);
        });

        $body.on('click', function (e) {
            const $eTarget = $(e.target);

            if ($eTarget.hasClass('nav active') && !$eTarget.parents('.nav').length) {
                if ($primaryNav.hasClass('-active')) {
                    $primaryNav.trigger('click');
                }

                $('.js-sub-nav.-active').trigger('click');
            }
        });

        // Window Listener
        that.$window.on('resize', debounce(function () {
            isMobileDevice = isMobile();

            if (isMobileDevice) {
                $primaryNav.removeClass('-active');
                $lvl1
                    .find('.icon-arrow.-active')
                    .removeClass('-active');

                $nav.removeClass('-active');
                $lvl1.removeClass('-active');
            } else {
                $lvl1.show();

                $('.js-sub-nav.-active').trigger('click');
            }
        }, 250));

        that.$window.on('scroll', debounce(function () {
            isMobileDevice = isMobile();

            if (!isMobileDevice) {
                $('.js-sub-nav.-active').trigger('click');
            }
        }, 250));
    }

    checkNavHeight() {
        const that = this;
        const $navLvl1 = $('#navigation .lvl1');

        that.$visibleArea = that.$window.outerHeight() - $('.header').outerHeight();

        $navLvl1.css({
            height: that.$visibleArea
        });
    }
}
