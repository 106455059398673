'use strict';

import { isValidEmail } from '../../_js/_util';

export default class Form {
    constructor() {
        $('.-js-submit').on('click', function (e) {
            let isValid = true;

            $('.form .form-input').each(function (i, v) {
                const $this = $(v),
                    $val = $this.val().trim();

                if ($val == '') {
                    isValid = false;
                    $this.parent().addClass('-error');
                } else {
                    $this.parent().removeClass('-error');
                }

                if ($this[0].type == 'email' && !isValidEmail($val)) {
                    isValid = false;
                    $this.parent().addClass('-error');
                }

                if (!isValid) {
                    e.preventDefault();
                }
            });


        });
    }
}
