'use strict';

import { isMobile } from '../../_js/_util';

export default class Header {
    constructor() {
        const that = this;

        that.isMobileDevice = false;

        $(window).on('resize scroll', that.toggleHeader);
    }

    toggleHeader() {
        let that = this;
        let $header = $('header');
        let st = $(this).scrollTop();
        let $headerHeight = $header.height();
        let lastScrollTop = 0;

        that.isMobileDevice = isMobile();

        if (!that.isMobileDevice) {
            if (st > lastScrollTop) {
                // scroll down
                if (st > $headerHeight) {
                    $header.addClass('hide');
                }
            } else {
                // scroll up
                if (st <= $headerHeight) {
                    $header.removeClass('hide');
                }
            }
        }

        lastScrollTop = st;
    }
}
