import { isIE } from "../../_js/_util";

'use strict';
export default class OrgChart {
    constructor() {
        if (isIE()) {
            $('html').addClass('-isIE');
        }

        $('.-js-toggle-dept').on('click', function () {
            $.fancybox.open('<div class="message">' + $(this).next().html() + '</div>');
        });
    }
}
